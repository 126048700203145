import React from "react";
import styles from './brands.module.css';

const Brands = () => {
  return (
    <>
      <div className={styles.globalStyle}>
        <div className={styles.brandsContainer}>
          <h1 className={styles.heading}>Understand Our Family Of Brands</h1>
          <div className={styles.brandCard}>
            <div className={styles.brandName}>
              <img
                className={styles.brandImage}
                src="https://i.imgur.com/b48obSE.png"
                alt="UNITWIST"
              />
              <div className={styles.description}>
                Our Patented Range Of Pipe Fittings, For Hot & Cold Water Installations In Residential, Industrial & Commercial Applications.
              </div>
            </div>
          </div>
          <div className={styles.brandCard}>
            <div className={styles.brandName}>
              <img
                className={styles.brandImage}
                src="https://i.imgur.com/MejNacd.png"
                alt="EASYTAIL"
              />
              <div className={styles.description}>
                Our Patented Range Of Fittings, For Water Meter, Water Pump & Water Purification Applications.
              </div>
            </div>
          </div>
          <div className={styles.brandCard}>
            <div className={styles.brandName}>
              <img
                className={styles.brandImage}
                src="https://i.imgur.com/J2r6LWF.png"
                alt="EASYBAT"
              />
              <div className={styles.description}>
                Our Range Of One-Piece Pipe Clips, Designed To Make The Installers Life More Convenient.
              </div>
            </div>
          </div>
          <div className={styles.brandCard}>
            <div className={styles.brandName}>
              <img
                className={styles.brandImage}
                src="https://i.imgur.com/Goyog4Q.png"
                alt="CLICKCLOSE"
              />
              <div className={styles.description}>
                Our Patented Range Of Taps, Utilizing A Unique Valve That Ensures Longevity, Durability & Less Maintenance.
              </div>
            </div>
          </div>
          <div className={styles.brandCard}>
            <div className={styles.brandName}>
              <img
                className={styles.brandImage}
                src="https://i.imgur.com/RF5I0dd.jpeg"
                alt="UNIFLOW"
              />
              <div className={styles.description}>
                Our Range Of HDPE Pipe Fittings for the Agricultural and Bulk Water Supply Market.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
