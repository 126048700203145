import React from 'react';
import styles from './pdf-download.module.css';

export default function PDFDownloadSection({ descText, heading, imageURL, pdfURL }) {

  const handleDownload = () => {
    window.open(pdfURL, '_blank');
  };

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.textColumn}>
        <h1 className={styles.subheading}>{heading}</h1>
        <div className={styles.descText}>
          {descText}
        </div>
      </div>
      <div className={styles.imageColumn}>
        <img
          className={styles.styledImage}
          src={imageURL}
          alt="PDF Preview"
        />
        <button className={styles.downloadButton} onClick={handleDownload}>
          View / Download
        </button>
      </div>
    </div>
  );
}
