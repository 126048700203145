
import React from 'react';
import styles from './side-image.widget.module.css';

export default function SideBySideImageText({ imageUrl, heading, body, percentageWidth }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textContainer}>
        <h2 className={styles.textHeader}>{heading}</h2>
        <p className={styles.textBody}>
          {body}
        </p>
      </div>
      <div className={styles.imageWrapper}>
        <img
          className={percentageWidth ? styles.imageFullWidth : styles.image}
          src={imageUrl}
          alt=""
        />
      </div>
    </div>
  );
}
