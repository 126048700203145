import React from "react";
import logoblack from '../../unitwistlogo.png';
import logoeee from '../../UNITWIST_U_LOGO.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import styles from './navbar.module.css';

export default function Navbar() {
  return (
    <div className={styles.navbarContainer}>
      <div className={styles.logoContainer}>
        <img src={logoblack} alt="Logo Black" className={styles.logo} />
        <img src={logoeee} alt="Logo EEE" className={styles.logoo} />
      </div>
      <div className={styles.centerText}>
        <a
          href="https://api.whatsapp.com/send?phone=27794177626"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.hotlineNumber}>
            <FontAwesomeIcon icon={faWhatsapp} className={styles.icon} />
            WhatsApp +27 79 417 7626
          </div>
        </a>
      </div>
    </div>
  );
}
