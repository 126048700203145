import React from 'react';
import styles from './our-mission.module.css';

export default function OurMission() {
  return (
    <section className={styles.missionSection}>
      <div className={styles.upperRow}>
        <div className={styles.videoContainer}>
          <iframe
            src={`https://player.vimeo.com/video/994591569?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479`}
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            title="BEHIND_THE_TECHNOLOGY_-_UNITWIST_ENGLISH_lvsomu"
            className={styles.styledIframe}
          />
        </div>
        <div className={styles.missionContent}>
          <h1 className={styles.missionTitle}>Why are we special?</h1>
          <p className={styles.missionText}>
            Unitwist stands out as the market&#39;s most user-friendly and durable fitting, offering
            affordability and a 25-year guarantee. Explore our top features below and watch the videos
            for more information.
          </p>
          <img
            className={styles.styledMissionImage}
            src="https://i.imgur.com/qHHPuks.gif"
            alt="Mission Image"
          />
        </div>
      </div>
      <div className={styles.missionPoints}>
        <div className={`${styles.point} ${styles.dark}`}>
          <h2 className={`${styles.pointTitle} ${styles.dark}`}>Works On Damaged Pipe</h2>
          <img
            className={styles.pointImage}
            src="https://i.imgur.com/AEZJrYc.png"
            alt="Works on Damaged Pipe"
          />
          <p className={`${styles.pointDescription} ${styles.dark}`}>
            Our fittings uniquely seal on pipes that are scratched, cut, or non-uniform in diameter,
            making them highly versatile, especially for maintenance & repair work.
          </p>
        </div>
        <div className={styles.point}>
          <h2 className={styles.pointTitle}>Works On Any Type Of Pipe</h2>
          <img
            className={styles.pointImageTwo}
            src="https://i.imgur.com/zOMDs4g.png"
            alt="Works on Any Type of Pipe"
          />
          <p className={styles.pointDescription}>
            Our fittings work with any pipe having a 15mm or 22mm outer diameter. For 16 or 20mm
            pipes, just use one of our male or female adaptors.
          </p>
        </div>
        <div className={`${styles.pointThree} ${styles.dark}`}>
          <h2 className={`${styles.pointTitle} ${styles.dark}`}>
            For Hot & Cold Water with UV Stabilisation
          </h2>
          <p className={`${styles.pointDescription} ${styles.dark}`}>
            Our pipes and fittings withstand temperatures from -10°C to 100°C at sea level. They are
            UV-stabilized for enhanced durability outdoors.
          </p>
        </div>
      </div>
    </section>
  );
}
