// VideoSection.js

import React from 'react';
import styles from './video-section.module.css';

export default function VideoSection({ videoUrl, descText, heading, logoUrl, size, buttonUrl }) {
  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.textColumn}>
        {logoUrl && <img src={logoUrl} alt="Logo" className={styles.logo} />}
        <h1 className={styles.subheading}>{heading}</h1>
        <div className={styles.descText}>{descText}</div>
      </div>

      <div className={styles.videoColumn} style={{ '--video-size': size ? `${size}%` : undefined }}>
        <iframe
          src={`https://player.vimeo.com/video/${videoUrl}?title=0&byline=0&portrait=0&badge=0&autopause=0&dnt=1&player_id=0&app_id=58479`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title="Video"
          className={styles.styledIframe}
        />
      </div>
      {buttonUrl && <a href={buttonUrl} className={styles.styledButton}>Find out more</a>}
    </div>
  );
}
